import './CreatorOffers.css'; // Create this CSS file for styling
import beaconIcon from '../../assets/function-icons/beaconIcon.png';
import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import toggleIcon from '../../assets/function-icons/toggle.svg';
import React from 'react';
import DreamSquare from '../../assets/game-squares/dream-square.png';
import BeaconSquare from '../../assets/game-squares/beacon-square.png';
import MojoSquare from '../../assets/game-squares/mojo-square.png';
import WanderSquare from '../../assets/game-squares/wander-square.png';
import CosmikSquare from '../../assets/game-squares/cosmik-square.png';
import SmolSquare from '../../assets/game-squares/smol-square.png';
import GlitchSquare from '../../assets/game-squares/glitch-square.png';
import AiSquare from '../../assets/game-squares/ai-square.png';
import BeaconBadge from '../../assets/game-squares/beacon-badge.png';
import RealmsSquare from '../../assets/game-squares/realms-square.png';
import { useNavigate } from 'react-router-dom';







const CreatorOffers = ({isLeftNavOpen = true}) => {
    const navigate = useNavigate(); 
    return (
        <div className={`offers-container header-app-route no-header-blur ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{backgroundColor: "#EAD4FF", overflowY: "scroll", height: "95vh"}}>



            <div className="campaigns-container" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
                <section className="current-offers" style={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", padding: "0px 30px", width: "100%"}}>
                    <h3 style={{color: "#590AA0", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat"}}>Sort By <img src={toggleIcon} alt="Toggle Icon" /></h3>
                    <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
                        <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "90%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>

                            <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
                                <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "230px"}}>Closed Campaign Offer</h2>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Timeline</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Deliverables</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Expires</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Full Offer Value</h3>
                            </div>
                    <hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 10px 0px"}}/>
                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={dreamrunnerIcon} alt="Game Icon" />
                                </div>

                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "190px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner</h2>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "190px", textAlign: "left", marginLeft: "5px"}}>The Soul Of Myra</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Mar 15 - Apr 10</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>3</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>7 Days</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$14,250</h3>
                                </div>
                            </div>

                            <div className="offer-button-container" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%", margin: "15px 0px -5px 0px"}}>
                                <button className="offer-button" style={{backgroundColor: "#A579D6", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => navigate('/dreamrunner-campaign')}>Review Offer</button>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px 10px 0px 20px"}}>Status: Reviewed</h3>

                                <svg width="38" height="38" viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
    <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none" 
            stroke-dasharray="84.68 162.76" stroke-dashoffset="85" />
</svg>
                            </div>


                        </div>
                        <div className="game-square">
                            <img src={DreamSquare} alt="Game Icon" style={{width: "157px", height: "157px"}}/>
                        </div>
                    </div>


                    <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px 0px 10px 0px", gap: "20px"}}>
                        <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "90%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>
                            <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
                                <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "230px", overflowX: "visible", whiteSpace: "nowrap"}}>Performance Campaign</h2>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Timeline</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Deliverables</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Expires</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Full Offer Value</h3>
                            </div>
                    <hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 10px 0px"}}/>
                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={beaconIcon} alt="Game Icon" />
                                </div>

                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "190px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner</h2>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "190px", textAlign: "left", marginLeft: "5px"}}>The Soul Of Myra</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Feb 25 - Apr 25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>1-10</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>7 Days</h3>
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "38px"}}>    
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$1,000</h3>
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px", marginTop: "2px"}}>Up to $10,000</h3>
                                    </div>


                                </div>
                            </div>



                            <div className="offer-button-container" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%", margin: "15px 0px -5px 0px"}}>
                                <button className="offer-button" style={{backgroundColor: "#A579D6", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}}>Review Offer</button>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px 10px 0px 20px"}}>Status: Unopened</h3>
                                <svg width="38" height="38" viewBox="0 0 100 100">
                                    <circle cx="50" cy="50" r= "35" stroke="#ccc" stroke-width="10" fill="none" />
                                </svg>
                            </div>


                        </div>
                        <div className="game-square">
                            <img src={BeaconBadge} alt="Game Icon" style={{width: "157px", height: "157px"}}/>
                        </div>
                    </div>


                </section>
            </div>
            <div className="Explore-campaigns" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", padding: "0px 30px"}}>
                    <div className="campaign-explorer" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", marginBottom: "50px"}}>
                        <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "800", fontFamily: "Montserrat", margin: "25px 0px"}}>Explore New Game+ Campaigns</h2>
                            <div className="campaign-card-container" style={isLeftNavOpen ? {display: "grid", gridTemplateColumns: "repeat(4, 3fr)", gap: "30px", transition: "gap 0.3s ease-in-out"} : {display: "grid", gridTemplateColumns: "repeat(4, 3fr)", gap: "30px", transition: "gap 0.3s ease-in-out"}}>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={MojoSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={CosmikSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>

                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={SmolSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={WanderSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={BeaconSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={AiSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={GlitchSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>
                                </div>
                                <div className="campaign-card" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                                    <img src={RealmsSquare} alt="Game Icon" style={isLeftNavOpen ? {width: "16vw", transition: "width 0.3s ease-in-out"} : {width: "20vw", transition: "width 0.3s ease-in-out"}}/>
                                    <button className="view-campaign-button" style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "170px", display: "flex", alignItems: "center", justifyContent: "center"}}>View Campaign</button>

                                </div>
                            </div>
                    </div>
                </div>
        </div>

    );
};

export default CreatorOffers; 