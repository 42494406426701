import React from 'react';
import './CreatorPayouts.css';
import beaconIcon from '../../assets/function-icons/beaconIcon.png';
import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import mojoMeleeIcon from '../../assets/function-icons/mojoMeleeIcon.png';
import cosmikBattleIcon from '../../assets/function-icons/cosmikBattleIcon.png';

const CreatorPayouts = ({isLeftNavOpen = true}) => {
  return <div className="Payouts-Container" style={{padding: '30px 30px', backgroundColor: '#EAD4FF', height: '95vh', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', gap: '20px'}}>
    <div className={`Payout-Cards ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>

        <div className={`Payout-Card ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{backgroundColor: '#ffffff', borderRadius: '10px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px 20px'}}>

            <h2 className="payout-card-title">In Escrow</h2>
            <h1 className="payout-card-amount">$11,000</h1>
            <button className="payout-card-button">Manage Escrow</button>
        </div>
        <div className={`Payout-Card ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{backgroundColor: '#ffffff', borderRadius: '10px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px 20px'}}>
            <h2 className="payout-card-title">Available To Claim</h2>
            <h1 className="payout-card-amount">$3,250</h1>
            <button className="payout-card-button">Claim Earnings</button>
        </div>
        <div className={`Payout-Card ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{backgroundColor: '#ffffff', borderRadius: '10px',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '10px 20px'}}>
            <h2 className="payout-card-title">All Time Revenue</h2>
            <h1 className="payout-card-amount">$24,250</h1>
            <button className="payout-card-button">Review Earnings</button>
        </div>
    </div>

    <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>

<div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "500", fontFamily: "Montserrat", margin: "0px", width: "330px", textAlign: "left"}}>Upcoming Earnings</h2>
    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Date Available</h3>
    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Total Deliverables</h3>
    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Status</h3>
    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "139px"}}>Amount</h3>
</div>
<hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 15px 0px"}}/>
<div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
    <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
       <img style={{ width: "30px", height: "30px", borderRadius: "10px" }} src={dreamrunnerIcon} alt="Game Icon" />
    </div>



    <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "290px"}}>
        <h2 style={{color: "#202020", fontSize: "16px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Dreamrunner </h2>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - The Soul Of Myra</h3>
        </div>

        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>02/15/25</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>12</h3>
        <h3 style={{color: "#3B006F", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Claimable</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$14,250</h3>

    </div>

</div>


<hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 5px 0px"}}/>


<div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
    <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
       <img style={{ width: "30px", height: "30px", borderRadius: "10px" }} src={cosmikBattleIcon} alt="Game Icon" />
    </div>



    <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "290px"}}>
        <h2 style={{color: "#202020", fontSize: "16px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Cosmik Battle</h2>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Season 2</h3>
        </div>

        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>02/14/25</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>7</h3>
        <h3 style={{color: "#3B006F", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Claimable</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$5,100</h3>
    </div>

</div>

<hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 5px 0px"}}/>

<div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
    <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
       <img style={{ width: "30px", height: "30px", borderRadius: "10px" }} src={mojoMeleeIcon} alt="Game Icon" />
    </div>



    <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "290px"}}>
        <h2 style={{color: "#202020", fontSize: "16px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Mojo Melee</h2>


        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Winter is Coming</h3>
        </div>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/10/25</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>10</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>In Escrow</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$2,500</h3>
    </div>

</div>

<hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 5px 0px"}}/>


<div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
    <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

       <img style={{ width: "30px", height: "30px", borderRadius: "10px" }} src={beaconIcon} alt="Game Icon" />
    </div>




    <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "290px"}}>
        <h2 style={{color: "#202020", fontSize: "16px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>The Beacon</h2>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Act II</h3>
        </div>


        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>05/21/25</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>5</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>In Escrow</h3>
        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$1,250</h3>
    </div>

</div>

</div>
     



  </div>;
};


export default CreatorPayouts;