import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import DreamSquare from '../../assets/game-squares/dream-square.png';
import './DreamRunnerCampaign.css';
import MyAccordion from '../MyAccordion.js';






const DreamrunnerCampaign = ({isLeftNavOpen = true}) => {
    return (

        <div className="dreamrunner-campaign-container" style={{backgroundColor: "#EAD4FF", overflowY: "scroll", height: "95vh", padding: "20px 30px"}}>


        <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>

<div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "90%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>

    <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%", marginBottom: "5px"}}>
        <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "230px"}}>Closed Campaign Offer</h2>

        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Full Offer Value</h3>
    </div> 
<hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 10px 0px"}}/>
    <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%", marginTop: "10px" }}>
        <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

           <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={dreamrunnerIcon} alt="Game Icon" />
        </div>

        <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start"}}>
            <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner</h2>

            <h3 style={{color: "#626063", fontSize: "16px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>- The Soul Of Myra</h3>
            </div>

            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px", textAlign: "right", marginRight: "10px"}}>$14,250</h3>
        </div>
    </div>

<div className="offer-details-description" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%", margin: "30px 0px 40px 0px"}}>
    <h3 style={{color: "#626063", fontSize: "18px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner launch campaign will engage players, create hype around Knights of the Ether on socials, and ensure a memorable entry into the world of immersive, collectible-driven fantasy gaming.</h3>
</div>



    <div className="offer-button-container" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%", margin: "15px 0px -5px 0px"}}>
        <div className={`offer-button-container-inner ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%"}}>
        <button className="offer-button" style={{backgroundColor: "rgba(0, 150, 18, 0.5)", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}}>Accept Offer</button>




        <button className="offer-button" style={{backgroundColor: "rgba(192, 0, 0, 0.5)", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}}>Counter Offer</button>
        <button className="offer-button" style={{backgroundColor: "#A579D6", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}}>More Details</button>

        <button className="offer-button" style={{backgroundColor: "rgba(165, 121, 214, 0.5)", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "8px 20px", borderRadius: "30px", width: "150px", display: "flex", alignItems: "center", justifyContent: "center"}}>Decline Offer</button>
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right", margin: "0px"}}>
            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px 0px 0px 20px", width: "150px"}}>Status: Reviewing</h3>
            <svg width="38" height="38" viewBox="0 0 100 100">
 
            <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
            <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none" 
            stroke-dasharray="84.68 162.76" stroke-dashoffset="85" />
            </svg>
        </div>
    </div>



</div>
<div className={`game-square ${isLeftNavOpen ? 'resized' : 'not-resized'}`}>
    <img src={DreamSquare} alt="Game Icon"/>
</div>
</div>

<div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
    <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>
    <MyAccordion />
    </div>  
</div>
</div>
    )
}

export default DreamrunnerCampaign;