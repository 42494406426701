import './CreatorOffers.css'; // Create this CSS file for styling
import beaconIcon from '../../assets/function-icons/beaconIcon.png';
import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import toggleIcon from '../../assets/function-icons/toggle.svg';
import mojoMeleeIcon from '../../assets/function-icons/mojoMeleeIcon.png';
import cosmikBattleIcon from '../../assets/function-icons/cosmikBattleIcon.png';
import React, { useState } from 'react';
import './CreatorCampaigns.css';
import PieChart from '../PieChart';


const CreatorCampaigns = ({ isLeftNavOpen }) => {
    const [isCheckbox1Checked, setIsCheckbox1Checked] = useState(false);
    const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false);


    const handleCheckbox1Change = () => {
        setIsCheckbox1Checked(!isCheckbox1Checked);
    }; 

    const handleCheckbox2Change = () => {
        setIsCheckbox2Checked(!isCheckbox2Checked);
    };


    const impressionsData = {
        labels: ['X', 'TikTok', 'YouTube'],
        datasets: [
            {
                label: 'Clicks',

                data: [5320, 2000, 1000], // Example data
                backgroundColor: ['rgba(171, 0, 199)', 'rgba(89, 10, 160)', 'rgba(223, 223, 223)'],
                borderRadius: 8,


            },
            {
                data: 100,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',

            }

        ],
    };

    // Prepare data for the clicks pie chart
    const clicksData = {
        labels: ['X', 'TikTok', 'YouTube'],
        datasets: [
            {
                label: 'Clicks',

                data: [300000, 700000, 400000], // Example data
                backgroundColor: ['rgba(171, 0, 199)', 'rgba(89, 10, 160)', 'rgba(223, 223, 223)'],
                borderRadius: 8,



            },
            {
                data: 100,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',

            }

        ],
    };


    return (
        <div className={`offers-container header-app-route no-header-blur ${isLeftNavOpen ? 'resized' : 'not-resized'}`} style={{backgroundColor: "#EAD4FF", overflowY: "scroll", height: "95vh"}}>
            <div className="campaigns-container" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "space-between" }}>
                <section className="current-offers" style={{display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", padding: "0px 30px", width: "100%"}}>
                    <h3 style={{color: "#590AA0", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat"}}>Sort By <img src={toggleIcon} alt="Toggle Icon" /></h3>
                    <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
                        <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>

                            <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
                                <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "500", fontFamily: "Montserrat", margin: "0px", width: "346px", textAlign: "left"}}>Current Campaigns</h2>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Start Date</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>End Date</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Deliverables</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Full Offer Value</h3>
                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "139px"}}>Status</h3>
                            </div>
                    <hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 15px 0px"}}/>
                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={dreamrunnerIcon} alt="Game Icon" />
                                </div>



                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Dreamrunner </h2>
                                    <h3 style={{color: "#626063", fontSize: "17px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - The Soul Of Myra</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>03/15/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/20/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>20 remaining</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$14,250</h3>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>   
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "91px"}}>Not Started</h3>
                                        <svg width="38" height="38" viewBox="0 0 100 100" style={{marginRight: "10px"}}>
                                             <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
                                        </svg>
                                    </div>
                                </div>

                            </div>


                            <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "15px 0px 10px 0px"}}/>
                            
                            
                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={cosmikBattleIcon} alt="Game Icon" />
                                </div>



                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "306.1px"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Cosmik Battle</h2>
                                    <h3 style={{color: "#626063", fontSize: "17px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Season 2</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>02/14/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>05/14/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>10 remaining</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$5,100</h3>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>   
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "91px"}}>In Progress</h3>
                                        <svg width="38" height="38" viewBox="0 0 100 100" style={{marginRight: "10px"}}>
                                             <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
                                             <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none" 
                                              stroke-dasharray="84.68 162.76" stroke-dashoffset="85" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "15px 0px 10px 0px"}}/>

                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={mojoMeleeIcon} alt="Game Icon" />
                                </div>



                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "306.1px"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>Mojo Melee</h2>


                                    <h3 style={{color: "#626063", fontSize: "17px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Winter is Coming</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>02/10/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>03/10/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>5 remaining</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$1,000</h3>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>   
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "91px"}}>In Progress</h3>
                                        <svg width="38" height="38" viewBox="0 0 100 100" style={{marginRight: "10px"}}>
                                             <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
                                             <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none" 
                                              stroke-dasharray="84.68 162.76" stroke-dashoffset="85" />
                                        </svg>
                                    </div>
                                </div>

                            </div>

                            <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "15px 0px 10px 0px"}}/>


                            <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>
                                <div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

                                   <img style={{ width: "40px", height: "40px", borderRadius: "10px" }} src={beaconIcon} alt="Game Icon" />
                                </div>




                                <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start", width: "306.1px"}}>
                                    <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px"}}>The Beacon</h2>

                                    <h3 style={{color: "#626063", fontSize: "17px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}> - Act II</h3>
                                    </div>

                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>01/21/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>02/21/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>0 remaining</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>$1,250</h3>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>   
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "91px"}}>Complete</h3>
                                        <svg width="38" height="38" viewBox="0 0 100 100" style={{marginRight: "10px"}}>
                                             <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none"/>
                                        </svg>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%"}}>
                        <h2 style={{color: "#202020", fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", margin: "10px 0px 2px 5px", textAlign: "left"}}>Quick View</h2>
                        <h2 style={{color: "#202020", fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", margin: "10px 0px 2px 10px", textAlign: "left"}}>|</h2>
                        <h3 style={{color: "#202020", fontSize: "12px", fontWeight: "1000", fontFamily: "Montserrat", textAlign: "left", margin: "10px 0px 2px 10px"}}>Today</h3>
                        <h3 style={{color: "#202020", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", textAlign: "left", margin: "10px 0px 2px 15px"}}>Week</h3>
                        <h3 style={{color: "#202020", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", textAlign: "left", margin: "10px 0px 2px 15px"}}>Month</h3>
                        <h3 style={{color: "#202020", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", textAlign: "left", margin: "10px 0px 2px 15px"}}>Year</h3>
                    </div>
                    <div>
                        <h3 style={{color: "#202020", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", textAlign: "left", margin: "10px 5px 2px 0px", width: "130px", textDecoration: "underline"}}>Manage Deliverables</h3>
                    </div>
                </div>


                <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
                <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>
                    <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
                         <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "500", fontFamily: "Montserrat", margin: "0px", width: "180px", textAlign: "left"}}>Deliverables</h2>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Campaign</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Due Date</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Status</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Platform</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>  </h3>
                    </div>
                    <hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 15px 0px"}}/>
                        
                        <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>

                            <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                    <div className="circular-checkbox" onClick={handleCheckbox1Change}>
                                        <input
                                          type="checkbox"
                                          checked={isCheckbox1Checked}
                                            onChange={handleCheckbox1Change}
                                            className="checkbox-input"
                                        />
                                        <span className={`checkbox-circle ${isCheckbox1Checked ? 'checked' : ''}`}></span>
                                    </div>
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px", width: "155px"}}>Short Form Video</h3>
                                </div>



                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>The Soul of Myra</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/20/25</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Not Started</h3>
                                    <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>TikTok</h3>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "129px"}}>   
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "109px"}}>Manage</button>

                                </div>

                                </div>

                            </div>

                            <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 10px 0px"}}/>

                        <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>


                        <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                            <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                <div className="circular-checkbox" onClick={handleCheckbox2Change}>
                                    <input
                                        type="checkbox"
                                        checked={isCheckbox2Checked}
                                        onChange={handleCheckbox2Change}
                                        className="checkbox-input"
                                    />
                                    <span className={`checkbox-circle ${isCheckbox2Checked ? 'checked' : ''}`}></span>
                                </div>

                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px", width: "155px"}}>Short Form Video</h3>
                            </div>


                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>The Soul of Myra</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/20/25</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Not Started</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>TikTok</h3>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "129px"}}>   
                            <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "109px"}}>Manage</button>

                            </div>

                        </div>

                        

                    </div>

                    <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 10px 0px"}}/>


                    <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>


                        <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                            <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                <div className="circular-checkbox" onClick={handleCheckbox2Change}>
                                    <input
                                        type="checkbox"
                                        checked={isCheckbox2Checked}
                                        onChange={handleCheckbox2Change}
                                        className="checkbox-input"
                                    />
                                    <span className={`checkbox-circle ${isCheckbox2Checked ? 'checked' : ''}`}></span>
                                </div>

                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px", width: "155px"}}>Short Form Video</h3>
                            </div>


                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>The Soul of Myra</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/20/25</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Not Started</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>TikTok</h3>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "129px"}}>   
                            <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "109px"}}>Manage</button>

                            </div>

                        </div>
                        </div>

                        <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 10px 0px"}}/>


                    <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>


                        <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                            <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                <div className="circular-checkbox" onClick={handleCheckbox2Change}>
                                    <input
                                        type="checkbox"
                                        checked={isCheckbox2Checked}
                                        onChange={handleCheckbox2Change}
                                        className="checkbox-input"
                                    />
                                    <span className={`checkbox-circle ${isCheckbox2Checked ? 'checked' : ''}`}></span>
                                </div>

                                <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "10px", width: "155px"}}>Short Form Video</h3>
                            </div>


                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>The Soul of Myra</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>04/20/25</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Not Started</h3>
                            <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>TikTok</h3>
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "129px"}}>   
                            <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "109px"}}>Manage</button>

                            </div>

                        </div>
                        </div>

                            
                        </div>                        

                </div>

                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", gap: "45px", marginTop: "15px", marginBottom: "30px"}}>
                    <div className="numbers-pie-chart" style={{ display: "flex", flexDirection: "row", alignItems: "start", justifyContent: "start", width: "50%", gap: "40px" }}>




                        <div className="numbers-pie-chart-item current-campaigns" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: "10px", width: "100%", height: "201px" }}>

                            <h3>Click-Thrus</h3>
                            <h2>8,320</h2>
                            <PieChart data={impressionsData} style={{height: "200px"}}/>

                        </div>
                        <div className="numbers-pie-chart-item current-campaigns" style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", gap: "10px", width: "100%", height: "201px" }}>
                        <h3>Impressions</h3>
                            <h2>1.4M</h2>
                            <PieChart data={clicksData} style={{height: "200px"}}/>
                        </div>
                    </div>

                    <div style={{width: "50%"}}>
                    <div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>

                <div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>
                    <div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%"}}>
                         <h2 style={{color: "#202020", fontSize: "18px", fontWeight: "500", fontFamily: "Montserrat", margin: "0px", width: "140px", textAlign: "left"}}>CTA Links</h2>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "210px"}}>URL</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Status</h3>
                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "140px"}}>  </h3>
                    </div>
                    <hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 15px 0px"}}/>
                        
                        <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>

                            <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", width: "140px"}}>Mojo Melee CTA</h3>
                                </div>
                                    <h3 style={{color: "#3B006F", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "210px", textAlign: "center"}}>play.mojomelee.io/creator1</h3>
                                    <h3 style={{color: "#E608C6", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Live</h3>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "140px"}}>   
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "50px"}}>Copy</button>
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "75px", marginLeft: "10px"}}>Statistics</button>


                                </div>

                                </div>

                            </div>

                            <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 10px 0px"}}/>

                        <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>


                        <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", width: "140px"}}>Dreamrunner CTA</h3>
                                </div>
                                    <h3 style={{color: "#3B006F", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "210px", textAlign: "center"}}>play.dreamrunner/creator1</h3>
                                    <h3 style={{color: "#E608C6", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Live</h3>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "140px"}}>   
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "50px"}}>Copy</button>
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "75px", marginLeft: "10px"}}>Statistics</button>


                                </div>

                                </div>

                        

                    </div>

                    <hr style={{width: "100%", border: "1px solid rgba(32, 32, 32, 0.05)", margin: "10px 0px 10px 0px"}}/>


                    <div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%" }}>


                    <div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "start"}}>
                                        <h3 style={{color: "#626063", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", width: "140px"}}>Cosmik Battle CTA</h3>
                                </div>
                                    <h3 style={{color: "#3B006F", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "210px", textAlign: "center"}}>play.cosmik.io/creator1</h3>
                                    <h3 style={{color: "#E608C6", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "129px"}}>Live</h3>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "140px"}}>   
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "50px"}}>Copy</button>
                                    <button className="manage-button" style={{fontSize: "13px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "8px", width: "75px", marginLeft: "10px"}}>Statistics</button>


                                </div>

                                </div>
                        </div>
                            
                        </div>                        

                </div>
                    </div>
                </div>



            </section>

        </div>
        </div>
    );
};


export default CreatorCampaigns;