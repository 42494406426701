import React from 'react';
import { Accordion } from 'react-bootstrap';

const MyAccordion = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>The Game</Accordion.Header>
                <Accordion.Body>
                    This is the content of the first accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>The Event</Accordion.Header>
                <Accordion.Body>
                    This is the content of the second accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Getting Started</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>My Deliverables</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Style & Tone</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Key Message & Components</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Media & Visual Focus</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>Final Notes</Accordion.Header>
                <Accordion.Body>
                    This is the content of the third accordion item.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default MyAccordion;
