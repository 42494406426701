import * as React from 'react';
import { Field } from '@base-ui-components/react/field';
import { Form } from '@base-ui-components/react/form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../StudioTypeform.css'; // Import your CSS module for styling
import LogoIcon from '../../assets/x-pfp.svg';
import './EventForm.css';
import './DreamRunnerCampaign.css';
import MyAccordion from '../MyAccordion.js';
import dreamrunnerIcon from '../../assets/function-icons/dreamrunnerIcon.png';
import DreamSquare from '../../assets/game-squares/dream-square.png';
import ClosedImg from '../../assets/images/closed-img.png';
import BountyImg from '../../assets/images/bounty-img.png';
import GrantImg from '../../assets/images/grant-img.png';
import OpenImg from '../../assets/images/create-img.png';
import continueIcon from '../../assets/continue-icon.svg';

const EventMilestoneForm = ({ formStatus, setFormStatus }) => {
    const [part, setPart] = React.useState(1);
    const [errors, setErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());

    const handleNext = () => {
        setPart(part + 1);
        if (part === 1) {
            setFormStatus('started'); // Update form status when moving to part 2
        }
        if (part === 0) {
            setFormStatus('not-started');
        }
    };

    const handleBack = () => {
        setPart(part - 1);
    };

    const submitForm = async (event) => {
        event.preventDefault();
        // Handle form submission logic here
        setLoading(true);
        // Simulate a server response
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
        // You can add validation and error handling here
        return formStatus;
    };

    return (
        <Form
        style={{
            alignItems: 'center',
            justifyContent: 'start',
            height: '100%',
            width: '100%'
        }}
        className={styles.Form}
        errors={errors}
        onClearErrors={setErrors}
        onSubmit={async (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const value = formData.get('url');
  
          setLoading(true);
          const response = await submitForm(value);
          const serverErrors = {
            url: response.error,
          };
  
          setErrors(serverErrors);
          setLoading(false);
        }}
      >
            {part === 1 && (
                <div className="event-milestone-form">
                    <img src={LogoIcon} alt="x-pfp" />
                    <h1>Create A Campaign</h1>
                    <h3>Welcome to New Game+. Let's create the perfect campaign for your next event.</h3>
                    <Field.Root name="url" className={styles.Field}>
                       <Field.Label className={styles.Label}></Field.Label>
                          <Field.Control
                            type="text"
                            required
                            placeholder="Campaign Name"
                            className="input"
                        />
                        <Field.Error className="error"></Field.Error>
                    </Field.Root>
                    <button className="next-button" type="button" onClick={handleNext} disabled={loading}>
                        Start
                    </button>
                    </div>
            )}
            {part === 2 && (
                <div className="event-milestone-form" style={{justifyContent: 'start', height: '100%'}}>
                    <h1 style={{marginTop: '0px'}}>Create A Campaign</h1>
                    <h3>Select the type of campaign you want to create</h3>  
                    <div style={{display: 'flex', flexDirection: 'row', gap: '100px 50px', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div onClick={handleNext} className="expandable-button-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '200px', height: '270px'}}>
                            <img src={ClosedImg} alt="Closed" style={{width: '100%', height: '45%', boxShadow: 'none'}}/>
                            <h3 style={{color: '#202020', fontSize: '19px', fontWeight: '400', fontFamily: 'Montserrat', margin: '20px 0px', textAlign: 'left', marginLeft: '0px'}}>Closed Campaign</h3>
                            <h3 style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', fontSize: '13px', fontWeight: '400', fontFamily: 'Montserrat', margin: '0px', textAlign: 'center', marginLeft: '0px'}}>Direct Offers for selected New Game+ creators with a fixed value.</h3>
                            <square className="expandable-button" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20%', width: '10%', height: '17%'}}> </square>
                            <img className="continue-icon" src={continueIcon} alt="Closed" style={{boxShadow: 'none'}}/>
                        </div>
                        <div onClick={handleNext} className="expandable-button-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '200px', height: '270px'}}>
                            <img src={OpenImg} alt="Open" style={{width: '100%', height: '45%', boxShadow: 'none'}}/>
                            <h3 style={{color: '#202020', fontSize: '19px', fontWeight: '400', fontFamily: 'Montserrat', margin: '20px 0px', textAlign: 'left', marginLeft: '0px'}}>Open Campaign</h3>
                            <h3 style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', fontSize: '13px', fontWeight: '400', fontFamily: 'Montserrat', margin: '0px', textAlign: 'center', marginLeft: '0px'}}>Performance-based offers made available to all New Game+ Creators.</h3>
                            <square className="expandable-button" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20%', width: '10%', height: '17%'}}> </square>
                            <img className="continue-icon" src={continueIcon} alt="Open" style={{boxShadow: 'none'}}/>
                        </div> 
                        <div onClick={handleNext} className="expandable-button-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '200px', height: '270px'}}>
                            <img src={BountyImg} alt="Bounty" style={{width: '100%', height: '45%', boxShadow: 'none'}}/>
                            <h3 style={{color: '#202020', fontSize: '19px', fontWeight: '400', fontFamily: 'Montserrat', margin: '20px 0px', textAlign: 'left', marginLeft: '0px'}}>Bounty Campaign</h3>
                            <h3 style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', fontSize: '13px', fontWeight: '400', fontFamily: 'Montserrat', margin: '0px', textAlign: 'center', marginLeft: '0px'}}>Open Campaigns for all Creators to compete for the top content.</h3>
                            <square className="expandable-button" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20%', width: '10%', height: '17%'}}> </square>
                            <img className="continue-icon" src={continueIcon} alt="Bounty" style={{boxShadow: 'none'}}/>
                        </div> 
                        <div onClick={handleNext} className="expandable-button-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', width: '200px', height: '270px'}}>
                            <img src={GrantImg} alt="Grant" style={{width: '100%', height: '45%', boxShadow: 'none'}}/>
                            <h3 style={{color: '#202020', fontSize: '19px', fontWeight: '400', fontFamily: 'Montserrat', margin: '20px 0px', textAlign: 'left', marginLeft: '0px'}}>Grant Campaign</h3>
                            <h3 style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '90%', fontSize: '13px', fontWeight: '400', fontFamily: 'Montserrat', margin: '0px', textAlign: 'center', marginLeft: '0px'}}>Campaigns submitted to live grant programs to subsidize spending.</h3>
                            <square className="expandable-button" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '20%', width: '10%', height: '17%'}}> </square>
                            <img className="continue-icon" src={continueIcon} alt="Grant" style={{boxShadow: 'none'}}/>
                        </div> 
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 'auto', gap: '20px'}}>
                    <button className="next-button" type="button" onClick={handleBack} disabled={loading}>
                        Back
                    </button>
                    </div>
                </div>
            )}
            {part === 3 && (
                <div className={`event-milestone-form ${formStatus === 'started' ? 'started' : 'not-started'}`} style={{display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start', width: '100%', marginTop: '20px', padding: '20px 0px'}}>
                    <div className="event-milestone-form left-form" style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', height: '100%', width: '50%', textAlign: 'left'}}>
                        <h1>Create A Campaign</h1>

                        <h3 className={styles.Label} style={{margin: '0px 0px 5px 15px', color: '#202020', fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat'}}>Select Game</h3>
                        <select className="input" style={{textAlign: 'left', paddingLeft: '12px'}}>
                            <option value="closed">Dreamrunner</option>
                            <option value="open">The Beacon</option>
                            <option value="bounty">Mojo Melee</option>
                            <option value="grant">Cosmik Battle</option>
                        </select>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start', height: '100%', width: '100%', textAlign: 'left'}}>
                        <Field.Root name="url" className={styles.Field} style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', height: '100%', width: '50%', textAlign: 'left'}}>
                       <h3 className={styles.Label} style={{margin: '0px 0px 5px 15px', color: '#202020', fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat'}}>Start Date</h3>
                       <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="input"
                            style={{textAlign: 'left', paddingLeft: '12px', height: '30px'}}
                        />
                        <Field.Error className="error"></Field.Error>
                    </Field.Root>
                    <Field.Root name="url" className={styles.Field} style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', height: '100%', width: '100%', textAlign: 'left'}}>
                       <h3 className={styles.Label} style={{margin: '0px 0px 5px 15px', color: '#202020', fontSize: '16px', fontWeight: '400', fontFamily: 'Montserrat'}}>End Date</h3>
                       <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="input"
                            style={{textAlign: 'left', paddingLeft: '12px', height: '30px'}}
                        />
                        <Field.Error className="error"></Field.Error>
                    </Field.Root>
                    </div>
                    <Field.Root name="url" className={styles.Field}>
                       <Field.Label className={styles.Label}></Field.Label>
                          <Field.Control
                            type="text"
                            required
                            placeholder="Campaign Name"
                            className="input"
                        />
                        <Field.Error className="error"></Field.Error>
                    </Field.Root>
                    <Field.Root name="url" className={styles.Field}>
                       <Field.Label className={styles.Label}></Field.Label>
                          <Field.Control
                            type="text"
                            required
                            placeholder="Campaign Name"
                            className="input"
                        />
                        <Field.Error className="error"></Field.Error>
                    </Field.Root>
                    <div className="button-div" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '10px', width: '100%', marginTop: 'auto'}}>
                    <button className="next-button" type="button" onClick={handleBack} disabled={loading}>
                        Back
                    </button>
                    <button className="next-button" type="submit" disabled={loading}>
                        Submit
                    </button>
                    </div>
                    </div>
                    <div className="campaign-preview" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '50%'}}   >
                    
                    
                    
                    
                    <div className="dreamrunner-campaign-container" style={{backgroundColor: "#EAD4FF", overflowY: "scroll", padding: "5px 5px", width: "100%"}}>


<div className="offer-item" style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
<div className={`game-square resized`} style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%"}}>
<img src={DreamSquare} alt="Game Icon" style={{width: "90%", height: "120px"}}/>
</div>
<div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "90%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>

<div className="offer-content-header" style={{display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", width: "100%", marginBottom: "5px"}}>
<h2 style={{color: "#202020", fontSize: "14px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "190px"}}>Closed Campaign Offer</h2>

<h3 style={{color: "#626063", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", width: "100px"}}>Full Offer Value</h3>
</div> 
<hr style={{width: "100%", border: "1px solid #EAD4FF", margin: "5px 0px 10px 0px"}}/>
<div className="offer-details" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%", marginTop: "10px" }}>
<div className="offer-campaign-icon" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

   <img style={{ width: "25px", height: "25px", borderRadius: "5px" }} src={dreamrunnerIcon} alt="Game Icon" />
</div>

<div className="offer-campaign" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
    <div className="offer-campaign-name" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start"}}>
    <h2 style={{color: "#202020", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner</h2>

    <h3 style={{color: "#626063", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>- The Soul Of Myra</h3>
    </div>

    <h3 style={{color: "#626063", fontSize: "12px", fontWeight: "800", fontFamily: "Montserrat", margin: "0px", width: "129px", textAlign: "right", marginRight: "10px"}}>$$$</h3>
</div>
</div>

<div className="offer-details-description" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: "#FFFFFF", width: "100%", margin: "20px 0px 10px 0px"}}>
<h3 style={{color: "#626063", fontSize: "14px", fontWeight: "400", fontFamily: "Montserrat", margin: "0px", textAlign: "left", marginLeft: "5px"}}>Dreamrunner launch campaign will engage players, create hype around Knights of the Ether on socials, and ensure a memorable entry into the world of immersive, collectible-driven fantasy gaming.</h3>
</div>



<div className="offer-button-container" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%", margin: "15px 0px -5px 0px"}}>
<div className={`offer-button-container-inner resized`} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%"}}>
<button className="offer-button" style={{backgroundColor: "rgba(0, 150, 18, 0.5)", color: "#FFFFFF", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "30px", width: "90px", display: "flex", alignItems: "center", justifyContent: "center"}}>Accept</button>




<button className="offer-button" style={{backgroundColor: "rgba(192, 0, 0, 0.5)", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "30px", width: "90px", display: "flex", alignItems: "center", justifyContent: "center"}}>Counter</button>
<button className="offer-button" style={{backgroundColor: "rgba(165, 121, 214, 0.5)", color: "#FFFFFF", fontSize: "14px", fontWeight: "600", fontFamily: "Montserrat", padding: "5px 10px", borderRadius: "30px", width: "90px", display: "flex", alignItems: "center", justifyContent: "center"}}>Decline</button>
</div>
<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right", margin: "0px"}}>
    <h3 style={{color: "#626063", fontSize: "12px", fontWeight: "600", fontFamily: "Montserrat", margin: "0px 0px 0px 5px", width: "90px"}}>Reviewing</h3>
    <svg width="25" height="25" viewBox="0 0 100 100">

    <circle cx="50" cy="50" r="35" stroke="#ccc" stroke-width="10" fill="none" />
    <circle cx="50" cy="50" r="35" stroke="#007bff" stroke-width="10" fill="none" 
    stroke-dasharray="84.68 162.76" stroke-dashoffset="85" />
    </svg>
</div>
</div>



</div>
</div>

<div className="offer-item" style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", padding: "10px 0px", width: "100%", margin: "0px", gap: "20px"}}>
<div className="offer-content-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "100%", backgroundColor: "#FFFFFF", borderRadius: "13.33px", padding: "15px 20px" }}>
<MyAccordion />
</div>  
</div>
</div>
                    
                    
                    
                    
                    
                    
                    </div>
                </div>
            )}
        </Form>
    );
};

export default EventMilestoneForm;