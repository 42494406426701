import React, { useEffect, useRef } from 'react';
import './CreateCampaign.css'; // Assuming you have some CSS for the modal

const Modal = ({ isVisible, onClose, children, isLeftNavOpen, formStatus }) => {
  const modalRef = useRef(); // Create a ref for the modal content

  useEffect(() => {
    const handleClickOutside = (event) => {
      const studioDashboard = document.querySelector('.dashboard-container');
      const clickYPosition = event.clientY; // Get the vertical position of the click

      if (
        studioDashboard &&
        studioDashboard.contains(event.target) &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        clickYPosition > 50 // Ensure the click is below the top 50px
      ) {
        onClose(); // Close the modal if clicked within StudioDashboard but outside the modal and below 50px
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside); // Add event listener
    } else {
      document.removeEventListener('mousedown', handleClickOutside); // Clean up listener
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Clean up on unmount
    };
  }, [isVisible, onClose]);

  return (
    <div className={`modalOverlay ${isLeftNavOpen ? 'modal-pushed' : 'modal-not-pushed'} ${isVisible ? 'visible' : ''} ${formStatus === 'started' ? 'started' : 'not-started'}`} ref={modalRef}>
      <div className={`modalContent `}>
        <button className="closeButton" onClick={onClose}>Close</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;